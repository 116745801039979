.app {
  height: 100vh;
  width: 100%;
  background-color: #E9F4ED;
  overflow-y: auto;
}

.btn-tabs:hover {
  background-color: #000 !important;
}

.btn-tabs {
  background-color: #00AB83 !important;
  color: #fff;
  font-size: 12px !important;
  padding: 10px 15px !important;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #E0E0E0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* tabs css */
.middle-tab {
  border-bottom: none !important;
  justify-content: space-between !important;
}

.middle-tab .css-1mbanyi {
  background-color: #000 !important;
  width: 24%;
  font-size: 0.875rem;
}

.css-1mbanyi {
  color: #fff !important;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.middle-tab .css-1mbanyi[aria-selected=true],
.middle-tab .css-1mbanyi[data-selected] {
  background-color: #00AB83 !important;
}

/* chat tabs */
.chat-tabs {
  border-bottom: none !important;
  justify-content: center !important;
}

.chat-tabs .css-1mbanyi {
  background-color: #9F9F9F !important;
  padding: 7px 25px !important;
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.chat-tabs .css-1mbanyi[aria-selected=true],
.chat-tabs .css-1mbanyi[data-selected] {
  background-color: #16999E !important;
  border: none !important;
}



/* timeline css */
.timeline {
  margin: 28px 35px;
  /* height: 4px;
  background-color: #F7AA28; */
  height: 2px;
  background-image: linear-gradient(90deg, #F7AA28, #F7AA28 75%, transparent 75%, transparent 100%);
  background-size: 10px 1px;
  border: none;
}

.timeline-progress {
  /* width: 20%; */
  height: 100%;
  background-color: #00AB83;
}

.timeline-items {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -12px;
  display: flex;
  justify-content: space-between;
}

.timeline-item {
  position: relative;
}

.timeline-item::before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: solid 1px #FEA729;
  display: block;
  border-radius: 100%;
}

.timeline-item-rejected .timeline-content {
  color: #e53e3e;
}

.timeline-content {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: #ddd; */
  width: 120px;
  padding: 5px 2px;
  border-radius: 5px;
  text-align: center;
  font-size: 9px;
  color: #9F9F9F;
}

/* .timeline-content::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #ddd;
  display: block;
  position: absolute;
  top: -8px;
  margin-left: -2px;
  left: 50%;
  transform: rotate(-45deg) translateX(-50%);
} */

.timeline-item.active::before {
  background-color: #00AB83;
  border: solid 1px #00AB83;
}

.timeline-item-rejected {
  position: relative;

}

.timeline-item-rejected::before {
  background-color: #e53e3e;
  border: solid 1px #e53e3e;
  content: '';
  width: 20px;
  height: 20px;
  /* background-color: #fff;
  border: solid 1px #FEA729; */
  display: block;
  border-radius: 100%;
}

.timeline-item.active .timeline-content {
  color: #343434;
  /* background-color: orange; */
}

/* .timeline-item.active .timeline-content::before {
  background-color: orange;
} */
.inputnm {
  height: 40px;
  width: 73%;
  background-color: #E9F4ED;
  border-radius: 0.375rem;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.inputnm:focus {
  box-shadow: #3182ce !important;
  border-color: #3182ce !important;
}

.upload-btn {
  border: solid 1px #00AB83;
  color: #00AB83;
  margin-left: 7px;
  font-size: 10px !important;
  padding-inline: 8px !important;
}

/**********************search loader********************/
.loader-outer {
  position: relative;
  /* right: 110px !important; */
  top: 35px !important;
  /* background-color: #00AB83; */

}

.loader-right {
  position: relative;
  right: 0px !important;
  top: 0px !important;

}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 15px;
  height: 15px;
  display: block;
  background: #00AB83;
  border-radius: 50%;
  position: relative;
  margin: 0 2px;
  animation: load 1s ease-in-out infinite;
}

.dot:nth-child(1) {
  animation-delay: 0.10s;
}

.dot:nth-child(2) {
  animation-delay: 0.25s;
}

.dot:nth-child(3) {
  animation-delay: 0.50s;
}

.dot:nth-child(4) {
  animation-delay: 0.75s;
}

.dot:nth-child(5) {
  animation-delay: 1s;
}

@keyframes load {

  0%,
  30%,
  100% {
    transform: scale(0.4);
  }

  20% {
    transform: scale(1);
  }
}